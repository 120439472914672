<template>
  <div class="pageIndex">
    <div class="bannerbox"></div>
    <div class="banner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in images"
            :key="index"
          >
            <img :src="item.src" alt="" />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>

      <div class="imgbox">
        <img src="@/assets/img/index_title.png" alt="" class="titleimg" />
        <img src="@/assets/img/cycle1-1.png" alt="" class="cycle1-1" />
        <img src="@/assets/img/cycle2.png" alt="" class="cycle2" />
        <img src="@/assets/img/cycle2.png" alt="" class="cycle2-1" />
        <img src="@/assets/img/cycle1-2.png" alt="" class="cycle1-2" />
        <img src="@/assets/img/cycle1.png" alt="" class="cycle1" />
        <img src="@/assets/img/cycle1.png" alt="" class="cycle1_1" />
        <img src="@/assets/img/index_icon.png" alt="" class="index_icon" />
        <img src="@/assets/img/index_icon2.png" alt="" class="index_icon2" />
      </div>
    </div>

    <div class="box news animate-up">
      <div class="title">公司新闻</div>
      <div class="flexrow">
        <div
          class="newsbox"
          v-for="(item, index) in newslists"
          :key="index"
          v-if="index < 3"
          @click="showNews(item)"
        >
          <div class="imgbox">
            <img :src="item.backGroundImages[0].previewUrl" alt="" />
          </div>
          <h1
            @mouseenter="newsHover(item, true)"
            @mouseleave="newsHover(item, false)"
          >
            {{ item.title }}
          </h1>
          <p>{{ item.releaseDate?.split(" ")[0] }}</p>
          <div
            class="righticon"
            :class="item.isHover ? 'righticon2' : ''"
          ></div>
        </div>
        <div class="newsbox" @click="goNews">
          <div class="imgbox">
            <img src="@/assets/img/morenews.jpg" alt="" />
          </div>
          <h1
            class="more"
            @mouseenter="isHover = true"
            @mouseleave="isHover = false"
          >
            查看更多资讯
          </h1>
          <div class="righticon" :class="isHover ? 'righticon2' : ''"></div>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="title animate-up">华凤企业宣传片</div>
      <div class="videobox" v-for="(item, index) in dataList" :key="index">
        <iframe
          title=""
          width="100%"
          height="100%"
          :src="item.video"
          frameborder="0"
          loading="lazy"
          seamless
          allowfullscreen
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        ></iframe>
      </div>
    </div>

    <div class="box moreBox">
      <div class="title animate-up">合作伙伴</div>
      <div class="imgFlexBox animate-up">
        <template v-for="(item, index) in imgList">
          <div class="imgbox" :key="index" v-if="index < 8">
            <img :src="item.src" alt="" />
          </div>
        </template>
      </div>

      <div class="imgFlexBox" v-show="showMore">
        <img src="@/assets/img/icon_9.png" alt="" class="imgbox" />
        <img src="@/assets/img/icon_10.png" alt="" class="imgbox" />
        <img src="@/assets/img/icon_11.png" alt="" class="imgbox" />
        <img src="@/assets/img/icon_12.png" alt="" class="imgbox" />
      </div>

      <div class="imgFlexBox" v-show="showMore">
        <img src="@/assets/img/icon_13.png" alt="" class="imgbox" />
        <img src="@/assets/img/icon_14.png" alt="" class="imgbox" />
        <img src="@/assets/img/icon_15.png" alt="" class="imgbox" />
        <img src="@/assets/img/icon_16.png" alt="" class="imgbox" />
      </div>

      <div class="moreTip" @click="showMore = !showMore" v-show="!showMore">
        更多合作伙伴
      </div>
    </div>

    <footer-bar class="animate-up"></footer-bar>

    <el-dialog
      :visible.sync="dialogVisible"
      custom-class="videoDialog"
      :close-on-click-modal="false"
      @open="stopSrcoll"
      @close="openSrcoll"
      destroy-on-close
      top="7vh"
    >
      <video :src="videoData" autoplay controls></video>
    </el-dialog>

    <el-drawer
      :visible.sync="drawer"
      size="50%"
      @open="stopSrcoll"
      @close="openSrcoll"
    >
      <div class="drawer" v-if="drawer">
        <div class="drawer_title">
          {{ drawerNew.title }}
        </div>
        <div class="name">华凤技术（南京）有限公司</div>
        <div class="time">{{ drawerNew.releaseDate }}</div>
        <div class="drawer_content" v-html="drawerNew.newsContent"></div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    Swiper,
  },
  data() {
    return {
      showMore: false,
      dialogVisible: false,
      dataList: [],
      videoData: "",
      newslists: [],
      drawer: false,
      drawerNew: {},
      images: [
        {
          src: require("@/assets/img/1-img.jpg"),
        },
        {
          src: require("@/assets/img/2-img.jpg"),
        },
        {
          src: require("@/assets/img/3-img.jpg"),
        },
        {
          src: require("@/assets/img/4-img.jpg"),
        },
      ],
      currentIndex: 0,
      intervalId: null,
      isHover: false,
      imgList: [
        {
          src: require("@/assets/img/icon_1.png"),
        },
        {
          src: require("@/assets/img/icon_2.png"),
        },
        {
          src: require("@/assets/img/icon_3.png"),
        },
        {
          src: require("@/assets/img/icon_4.png"),
        },
        {
          src: require("@/assets/img/icon_5.png"),
        },
        {
          src: require("@/assets/img/icon_6.png"),
        },
        {
          src: require("@/assets/img/icon_7.png"),
        },
        {
          src: require("@/assets/img/icon_8.png"),
        },
        {
          src: require("@/assets/img/icon_9.png"),
        },
        {
          src: require("@/assets/img/icon_10.png"),
        },
        {
          src: require("@/assets/img/icon_11.png"),
        },
        {
          src: require("@/assets/img/icon_12.png"),
        },
        {
          src: require("@/assets/img/icon_13.png"),
        },
      ],
    };
  },
  beforeDestroy() {
    this.dialogVisible = false;
    clearInterval(this.intervalId);
  },
  mounted() {
    this.openSrcoll();
    this.getData();

    this.swiper = new Swiper(".swiper-container", {
      // 可选的配置参数，根据需要进行配置
      slidesPerView: 1,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      autoplay: {
        delay: 1200,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      // effect: "fade",
    });

    this.$nextTick(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("active");
          } else {
          }
        });
      });

      document.querySelectorAll(".animate-up").forEach((element) => {
        observer.observe(element);
      });
    });
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex].src;
    },
  },
  methods: {
    getData() {
      this.$api.selectNews({ type: 0 }).then((res) => {
        this.newslists = res ? res : [];
        this.newslists.forEach((item) => {
          item.releaseDate = this.timestampToDate(item.releaseDate);
        });
      });

      this.$api.companyCoreTechnologySelectAll().then((res) => {
        this.dataList = res;
      });
    },
    stopSrcoll() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.documentElement.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },

    openSrcoll() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.documentElement.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    goNews() {
      this.$router.push({
        path: "/companyNews",
      });
    },
    showNews(item) {
      this.drawerNew = item;
      this.drawer = true;
    },
    timestampToDate(timestamp) {
      const date = new Date(timestamp); // 时间戳通常是以秒为单位的，需要乘以1000转换为毫秒
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份是从0开始的，所以需要加1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    newsHover(item, flag) {
      this.$set(item, "isHover", flag);
    },
    newsHover2(item, flag) {
      this.$set(this, "isHover", flag);
    },
  },
};
</script>

<style lang="less" scoped>
.pageIndex {
  padding: 0;
  .box {
    .title {
      padding: 170px 0 80px;
      font-size: 48px;
      font-weight: 400;
      color: #fff;
      text-align: center;
      font-weight: 700;
    }
    .flexrow {
      display: flex;
      width: 100%;
      .flexbox {
        flex: 1;
      }
    }
    .videobox {
      height: 800px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: 100% 100%;
      position: relative;
      margin-bottom: 80px;
      &:last-child {
        margin-bottom: 0;
      }
      div {
        display: flex;
        align-items: center;
      }
      h1 {
        display: inline-block;
        font-size: 48px;
        line-height: 64px;
        font-weight: 400;
        color: #fff;
        z-index: 99;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        line-height: 64px;
        height: 64px;
        text-align: center;
        font-weight: 400;
        color: #fff;
        margin: 0 24px;
        position: relative;
        top: -4px;
      }

      .play {
        position: absolute;
        bottom: 40px;
        left: 50px;
        width: 99px;
        cursor: pointer;
      }

      .bgimg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
    .videobox_1 {
      background-image: url("~@/assets/img/index_bg_1.jpg");
    }
    .videobox_2 {
      background-image: url("~@/assets/img/index_bg_2.jpg");
    }
    .videobox_3 {
      background-image: url("~@/assets/img/index_bg_3.jpg");
    }
    .imgFlexBox {
      display: flex;
      padding: 0 80px;
      flex-wrap: wrap;
      justify-content: space-between;
      .imgbox {
        width: 0;
        flex: 0 0 calc(25% - 160px);
        height: 100px;
        margin: 0 160px 64px 0;
        box-sizing: border-box;

        img {
          display: block;
          width: 240px;
          height: 100px;
          margin: auto;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  .moreBox {
    padding: 0 0 160px;
    text-align: center;
    // border-bottom: 1px solid #8892a1;
    .moreTip {
      display: inline-block;
      font-size: 20px;
      color: #fff;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      width: auto;
      border-bottom: 1px solid #fff;
      padding: 0 0 4px;
      &:hover {
        color: #009ade;
        border-color: #009ade;
      }
    }
  }

  .bannerbox {
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .text {
      position: relative;
      z-index: 222;
      color: #fff;
      bottom: -50px;
      opacity: 0;
      animation: fade-in-from-bottom 1.5s ease-out forwards;
      h1 {
        font-size: 56px;
        line-height: 84px;
        font-family: "AlimamaShuHeiTi-Bold";
        font-weight: normal;
      }
    }
  }

  .banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    // width: 1920px;
    // height: 1080px;
    .video {
      width: 1920px;
      height: 100%;
    }
    video {
      width: 1920px;
      height: 100%;
      object-fit: cover;
    }
    .imgbox {
      position: absolute;
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      z-index: 99;
      .titleimg {
        width: 943px;
        height: 114px;
      }
      .cycle1-1 {
        position: absolute;
        width: 57px;
        height: 60px;
        top: 45vh;
        left: 20vw;
        animation: rotate 5s linear infinite;
      }

      .cycle2 {
        position: absolute;
        width: 76px;
        height: 73px;
        top: 60vh;
        left: 31.5vw;
        animation: rotate 5s linear infinite;
      }

      .cycle2-1 {
        position: absolute;
        width: 76px;
        height: 73px;
        top: 35vh;
        left: 75vw;
        animation: rotate 5s linear infinite;
      }

      .cycle1-2 {
        position: absolute;
        width: 76px;
        height: 73px;
        top: 65vh;
        left: 55vw;
        animation: rotate 5s linear infinite;
      }

      .cycle1 {
        position: absolute;
        width: 37px;
        height: 40px;
        top: 35vh;
        left: 32vw;
        animation: rotate 5s linear infinite;
      }

      .cycle1_1 {
        position: absolute;
        width: 37px;
        height: 40px;
        top: 38vh;
        left: 64vw;
        animation: rotate 5s linear infinite;
      }

      .index_icon {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 40vh;
        left: 56vw;
      }

      .index_icon2 {
        position: absolute;
        width: 18px;
        height: 18px;
        top: 60vh;
        left: 63.5vw;
      }

      @keyframes rotate {
        from {
          transform: rotate(0deg); /* 从0度开始旋转 */
        }
        to {
          transform: rotate(360deg); /* 旋转360度，即一圈 */
        }
      }
    }
  }

  .news {
    .newsbox {
      margin: 0 0 0 30px;
      &:nth-child(1) {
        margin-left: 0;
      }
      width: 378px;
      background: #232429;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      .imgbox {
        width: calc(100% - 10px);
        height: 310px;
        margin: 5px 5px 0;
        overflow: hidden;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        transition: transform 0.6s ease;
        &:hover {
          transform: scale(1.1);
        }
      }

      h1 {
        font-size: 20px;
        line-height: 30px;
        height: 60px;
        font-weight: 400;
        color: #fff;
        margin: 30px 0 0;
        padding: 0 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* 控制显示的行数 */
        overflow: hidden; /* 隐藏溢出的内容 */
        text-overflow: ellipsis; /* 显示省略号 */
        &:hover {
          color: #009ade;
        }
      }
      .more {
        font-size: 20px;
        padding-top: 24px;
      }
      p {
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;
        text-align: left;
        color: #999999;
        padding: 0 24px;
        margin: 25px 0 0;
        height: 70px;
      }
      // .righticon {
      //   position: absolute;
      //   width: 16px;
      //   height: 16px;
      //   background-image: url("~@/assets/img/right2.png");
      //   background-size: 100% 100%;
      //   bottom: 40px;
      //   right: 24px;
      //   opacity: 0;
      // }
      // .righticon2 {
      //   animation-name: flyIn; /* 动画名称 */
      //   animation-duration: 0.6s; /* 动画持续时间 */
      //   animation-fill-mode: forwards; /* 动画结束后保留最后一个关键帧的样式 */
      // }
    }
  }
}

::v-deep .videoDialog {
  background: none;
  width: 1405px;
  height: 791px;
  position: relative;
  .el-dialog__header {
    background: none;
    position: absolute;
    top: 40px;
    right: 0px;
    z-index: 9999;
  }
  .el-dialog__body {
    // background: rgba(0, 0, 0, 0.3);
    background: none;
    width: 1405px;
    height: 791px;
    overflow: hidden;
  }

  video {
    width: 1405px;
    height: 791px;
    display: block;
    margin: auto;
  } /* 设置控制栏的高度 */

  // .el-dialog__close {
  //   font-size: 45px;
  // }
}

@media (max-width: 720px) {
  .news {
    display: block;
  }
}

@keyframes flyIn {
  from {
    /* 动画开始时的状态 */
    right: 100px;
    opacity: 0; /* 开始时透明度为0 */
  }
  to {
    /* 动画结束时的状态 */
    right: 24px; /* 结束时元素移动到右边100%的位置 */
    opacity: 1; /* 结束时完全可见 */
  }
}

.animate {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.animate-on-scroll {
  opacity: 1;
  transform: translateY(0);
}

.swiper-container {
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    content: "";
    position: absolute;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.swiper-pagination {
  bottom: 40px;
}

::v-deep .swiper-pagination-bullet {
  background: #666;
  opacity: 1;
}

::v-deep .swiper-pagination-bullet-active {
  background: #009ade;
}
</style>
